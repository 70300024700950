<template>
  <div class="center">
    <!--列表label-->
    <head-layout :head-title="this.$t('cip.desk.notice.title.indexHeadTitle')"
                 :head-btn-options="headBtnOptions"
                 @head-remove="handleDelete"
                 @head-add="headAdd"></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :head-title="this.$t('cip.desk.notice.title.indexHeadTitle')"
                 :table-options="option"
                 :table-data="data"
                 :table-loading="loading"
                 :data-total="page.total"
                 :grid-row-btn="gridRowBtn"
                 :page="page"
                 @grid-row-detail-click="rowView"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @gird-handle-select-click="selectionChange"
                 @row-publish="rowPublish"
                 @row-view="rowView"
                 @row-del="rowDel"
                 @row-broadcast="rowBroadcast">
      <template #customBtn="{row}">
        <el-button
          v-if="permission.notice_edit"
          :disabled="row.status == 2"
          style="margin: 0 3px"
          type="text"
          size="small"
          @click="rowEdit(row)"
        >{{ $t('cip.cmn.btn.editBtn') }}
        </el-button>
      </template>
    </grid-layout>
    <el-dialog
      :title="this.$t('cip.desk.notice.title.settingHeadTitle')"
      :visible.sync="settings">
      <avue-form
        :option="selOption"
        v-model="selForm"
      ></avue-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="settings = false">{{ $t("cip.cmn.btn.celBtn") }}</el-button>
        <el-button type="primary" @click="submit">{{ $t("cip.cmn.btn.defBtn") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {getList, remove, publish} from "@/api/desk/notice";
import {mapGetters} from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {getList as getBroadcastList, play} from "@/api/system/broadcast";

export default {
  components: {HeadLayout, GridLayout},
  data() {
    return {
      selOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [{
          label: this.$t(`cip.plat.app.broadcast.menu.selectBroadcast`),
          prop: 'select',
          type: 'select',
          multiple: true,
          dicData: [],
          span: 24,
          props: {
            label: "address",
            value: "id"
          },
        }]
      },
      selForm: {},
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      //改动要连同首页的一起改
      broadcast: {
        "text": "",
        "vcn": "xiaofeng",
        "speed": 50,
        "volume": 50,
        "rdn": "0",
        "rcn": "1",
        "reg": 2,
        "sync": false,
        "queue": false,
        "loop": {
          "duration": 999,
          "times": 1,
          "gap": 1
        },
        "prompt": false
      },
      selectionList: [],
      option: {
        index: true,
        indexLabel: this.$t("cip.cmn.title.SerialNumber"),
        menuWidth: 200,
        linklabel: "title",
        column: [
          {
            label: this.$t('cip.desk.notice.field.title'),
            prop: "title",
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.desk.notice.field.category'),
            type: "select",
            align: "center",
            dicUrl: "/api/sinoma-system/dict/dictionary?code=notice",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
            prop: "category",
            overHidden: true,
            width: 140,
          },
          {
            label: this.$t('cip.desk.notice.field.publishTime'),
            prop: "releaseTime",
            align: "center",
            overHidden: true,
            width: 200,
          },
          {
            label: this.$t('cip.desk.notice.field.publish_status'),
            prop: "status",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=desk_publish",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
            align: "center",
            overHidden: true,
            width: 140,
          },
        ]
      },
      settings: false,
      data: []
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.notice_add, false),
        publish: this.vaildData(this.permission.publish, false),
        viewBtn: this.vaildData(this.permission.notice_view, false),
        delBtn: this.vaildData(this.permission.notice_delete, false),
        editBtn: this.vaildData(this.permission.notice_edit, false),
        broadcastBtn: this.vaildData(this.permission.notice_broadcastBtn, false),
        settingBtn: this.vaildData(this.permission.notice_settingBtn, false)
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: this.$t('cip.desk.notice.field.title'),
          prop: "title",
          span: 4,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.desk.notice.field.title'),
        },
      ]
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.permission.notice_add) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.addBtn'),
            emit: "head-add",
            btnOptType: 'add',
          });
      }
      if (this.permission.notice_delete) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "head-remove",
            btnOptType: 'remove',
          });
      }
      return buttonBtn;
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      let buttonBtn = [];
      // if (this.permission.notice_view) {
      //   buttonBtn.push(
      //     {
      //       label: this.$t('cip.cmn.btn.viewBtn'),
      //       emit: "row-view",
      //     });
      // }
      if (this.permission.notice_delete) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "row-del",
          })
      }
      if (this.permission.publish) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.publishBtn'),
            emit: "row-publish",
          })
      }
      // if (this.permission.notice_broadcastBtn) {
      //   buttonBtn.push(
      //     {
      //       label: this.$t('cip.desk.notice.btn.broadcastBtn'),
      //       emit: "row-broadcast",
      //     })
      // }
      return buttonBtn;
    },
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    headAdd() {
      this.$router.push({
        path: `/notice/add`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowView(row) {
      console.log(row,'271')
      this.$router.push({
        path: `/notice/edit`,
        query: {
          id: row.id,
        }
      })
    },
    rowEdit(row) {
      if (row.status == 2) return;
      this.$router.push({
        path: `/notice/edit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    rowPublish(row) {
      publish(row);
      this.onLoad(this.page, {});
    },
    rowBroadcast(row) {
      this.broadcast.text = row.title
      this.settings = true
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(row.id).then((res) => {
            if (res.data.code == 200) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            }
          })
        })
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(this.ids).then((res) => {
            if (res.data.code == 200) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            }
          })
        })
    },
    submit() {
      if (this.selForm.select.length == 0) {
        this.$message.warning(this.$t("cip.desk.notice.msg.broadcast"))
        return;
      }
      let ids = [];
      this.selForm.select.forEach(ele => {
        ids.push(ele);
      });
      play(ids.join(","), this.broadcast.text).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"))
          this.settings = false
        }
      })
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.loading = true;
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionList = [];
      });
    },
    getBroadcastList() {
      getBroadcastList().then(res => {
        const data = res.data.data;
        this.findObject(this.selOption.column, 'select').dicData = data
      });
    }
  },
  // 初始化数据 add by steve
  mounted() {
    this.onLoad(this.page, {});
    this.getBroadcastList()
  },
};
</script>
